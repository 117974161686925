.theme-select-parent {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  gap: 10px;
  margin-top: 30px;
  min-height: 30px;
}

.circle-parent {
  min-width: 30px;
  min-height: 30px;
}

.circle {
  border-radius: 100%;
  height: 20px;
  width: 20px;
  transition-timing-function: ease-in;
}

.circle:hover,
.selected {
  background-color: blue;
  border: 1px solid red;
  border-radius: 100%;
  /* padding: 5px; */
  height: 25px;
  width: 25px;
  cursor: pointer;
  /* background-color: #f5b4c0; */
  box-shadow: 0px 15px 20px rgba(253, 135, 143, 0.4);
  color: #fff;
  transform: translateY(-7px);
  transition:
    transform 1s,
    box-shadow 1s,
    background-color 1s,
    color 1s;
  transition-timing-function: linear;
}
